<template>
  <v-hover>
    <v-layout
      slot-scope="{ hover }"
      :style="{
        cursor: 'pointer',
        backgroundColor: hover ? '#F5FAFE' : '#F9F9F9',
      }"
      px-4
      py-1
      align-center
    >
      <v-layout align-center @click.prevent="handleSelection">
        <v-flex class="text-style ml-4">
          <DefaultLabel class="text-truncate">{{ value.title }}</DefaultLabel>
        </v-flex>
        <v-spacer></v-spacer>
      </v-layout>
      <v-flex shrink>
        <v-checkbox
          class="pa-0 ma-0 zg-prmd-checkbox-style"
          hide-details
          v-model="internalSelectedPairs"
          multiple
          :value="`${value.category}_${value.id}`"
        ></v-checkbox>
      </v-flex>
    </v-layout>
  </v-hover>
</template>

<script>
export default {
  props: {
    value: {},
    selectedPairs: {}
  },
  computed: {
    internalSelectedPairs: {
      get() {
        return this.selectedPairs;
      },
      set(value) {
        this.$emit("update:selectedPairs", value);
      }
    }
  },
  components: {
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
  },
};
</script>

<style lang="scss">
.zg-prmd-checkbox-style .v-input--selection-controls__input {
  margin-right: 0px !important;
}
</style>

<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-style {
  max-width: 170px !important;
}
</style>